import React from 'react';

const SvgIconSeguranca = props => (
  <svg width={22} height={31} viewBox="0 0 22 31" {...props}>
    <g fill="#3E9814">
      <path
        d="M12 2.94C9.271 5.753 2.91 6.69 2.91 6.69v14.502c-.001 2.634 1.34 5.075 3.53 6.43l5.56 3.44 5.558-3.44c2.19-1.355 3.531-3.796 3.531-6.43V6.69C14.726 6.69 12 2.94 12 2.94z"
        opacity={0.3}
      />
      <path d="M19.09 3.753c-5.787 0-8.264-3.241-8.364-3.375a.903.903 0 0 0-.663-.375.877.877 0 0 0-.707.272C6.877 2.834.841 3.753.78 3.762A.929.929 0 0 0 0 4.69v14.503c.008 2.96 1.514 5.703 3.972 7.234l5.559 3.44a.885.885 0 0 0 .936 0l5.56-3.44c2.458-1.531 3.963-4.273 3.971-7.234V4.69c0-.518-.407-.937-.909-.937zm-.91 15.44c-.008 2.302-1.179 4.434-3.09 5.624L10 27.97l-5.091-3.151c-1.912-1.19-3.083-3.322-3.091-5.624V5.482c1.677-.323 5.644-1.26 8.117-3.239 2.27 2.102 5.195 3.295 8.245 3.36v13.59z" />
      <path d="M6.097 15.276L4.81 16.602l2.727 2.812a.89.89 0 0 0 1.286 0l7.272-7.5-1.286-1.325-6.629 6.837-2.084-2.15z" />
    </g>
  </svg>
);

export default SvgIconSeguranca;
