import _deburr from 'lodash/deburr';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import _remove from 'lodash/remove';
import _uniqBy from 'lodash/uniqBy';

export interface SelectType {
  label: string;
  value: string;
}

export interface Icons {
  name: string;
  icon: string;
}

export const mapValueToObjectLabelValue = (value: [], field: string) => {
  return _map(value, (v: any) => {
    return { label: v[field], value: v[field] };
  });
};

export const mapValueToObjectLabelValueWithId = (value: any[], field: string) => {
  return _map(value, (v: any) => {
    const { id } = v;
    const idNumber = parseInt(id.replace(/\D+/g, ''), 10);

    return { label: v[field], value: idNumber };
  });
};

export const mapValueToObjectLabelValueWithVehicleType = (value: any[], field: string) => {
  return _map(value, (v: any) => {
    const { vehicle_type } = v;
    const idVehicle = parseInt(vehicle_type, 10);

    return { label: v[field], value: idVehicle };
  });
};

export const ellipsis = (phrase: string, where: number) => {
  if (!phrase) {
    return phrase;
  }

  return phrase.substring(0, where) + (phrase.length > where ? '...' : '');
};

export const unique = (array: SelectType[]) =>
  _orderBy(_uniqBy(array, 'value'), ['label', 'value']);

export const toLabelValue = (value: string) => ({ label: value, value });

export const nameInitials = (name: string) => {
  if (!name || name.indexOf(' ') === -1) {
    return name;
  }

  const ignore = ['do', 'da', 'de', 'no', 'na'];
  const title = _filter(name.split(' '), (singleName: string) => !_includes(ignore, singleName));
  const firstLetter = title && title[0].charAt(0).toLocaleUpperCase();
  const secondLetter = title[1] && title[1].charAt(0).toLocaleUpperCase();

  if (title.length >= 2) {
    return `${firstLetter}${secondLetter}`;
  }

  return `${firstLetter}`;
};

export const toFloat = (value: any) => {
  if (value === null) {
    return value;
  }

  if (typeof value === 'number') {
    return value;
  }

  if (value.indexOf(',') !== -1) {
    return parseFloat(value.replace('.', '').replace(',', '.'));
  }

  return parseFloat(value);
};

export const getItemsFromLocalStorage = (storage: string, items: string[], prefix?: string) => {
  return _map(items, item => {
    const havePrefix = prefix ? `${prefix}.` : '';
    return _get(JSON.parse(localStorage.getItem(storage) || ''), `${havePrefix}${item}`, null);
  });
};

export const onRemoveSpecificItemInArray = (array: any[], value: string | number) => {
  _remove(array, item => item === value);
  return array;
};

export const unCheckedAllCheckBox = (singleClass: string) => {
  const allCheckBox: NodeListOf<HTMLInputElement> = document.querySelectorAll(`.${singleClass}`);
  return _map(allCheckBox, item => (item.checked = false));
};

export const isPlateTest = (plate: string) => {
  const PLATE_FORMAT = /^([a-zA-Z]{3}(-?)[0-9]{1}[0-9a-zA-Z]{1}[0-9]{2})$/;
  return PLATE_FORMAT.test(plate);
};

export const isSeatsAmountTest = (seatsAmount: number): boolean => {
  return seatsAmount >= 15;
};

export const isNumber = (e: any) => {
  const numberTest = /^[0-9]*$/;
  return numberTest.test(e.toString());
};

export const formatDate = (date: string | null) => {
  if (!date) {
    return '00/00/0000';
  }
  const arrayDate = date.substring(0, 10).split('-');
  return `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;
};

export const removeAccent = (text: any) => {
  return text
    .replace(/[ÁÀÂÃàáâã]/, 'a')
    .replace(/[ÉÈÊéèê]/, 'e')
    .replace(/[ÍÌÎíìî]/, 'i')
    .replace(/[ÓÒÔÕóòôõ]/, 'o')
    .replace(/[ÚÙÛúùû]/, 'u')
    .replace(/[Çç]/, 'c');
};

export const lowerNoAccent = (text: string) => removeAccent(text).toLowerCase();

export const spaceToHyfen = (text: string) => text.replace(/\s/g, '-');

export const noPercent = (text: string) => text.replace(/\%/g, '');

const removeInvalidCharacters = (text: string) => text.replace(/[@|$|#|¨]/g, '');

export const noDots = (text: any) => text.toString().replace(/\./g, '');

export const externalUrlRegex =
  /^(?:(?:https?):\/\/)?(?:(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
export const findNumberDotNumber = (e: string) => {
  return /[0-9]{1}\.[0-9]{1}/.exec(e) || '--';
};

export const getRandomInt = (min = 0, max = 0) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

export const slugFormat = (value: string) =>
  noPercent(spaceToHyfen(removeInvalidCharacters(_deburr(value).toLowerCase())));

export const getParameterByName = (name: string, url?: string): string => {
  url = !url ? window.location.href : url;
  name = name.replace(/[\[\]]/g, '\\$&');

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);

  if (!results) {
    return '';
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const verifyFileExists = (iconName: string) => {
  const fileIcon = iconName.replace('Svg', '');

  try {
    require(`../components/Icons/components/${fileIcon}.js`);
    return true;
  } catch (err) {
    return false;
  }
};

export const getIcons = (name: string, icons: Icons[] | undefined) => {
  const icon = _filter(icons, (item: Icons) => {
    return item.name === name;
  });

  if (icon.length && verifyFileExists(icon[0].icon)) {
    return icon[0].icon;
  }

  return 'SvgIconServices';
};

export const ellipsizeTextBox = (id: string) => {
  const el = document.getElementById(id);

  if (el) {
    const wordArray = el.innerHTML.split(' ');

    while (el.scrollHeight > el.offsetHeight) {
      wordArray.pop();
      el.innerHTML = wordArray.join(' ') + '...';
    }
  }
};

export const isStore = () => {
  const currentUrlPage = typeof window !== 'undefined' ? window.location.pathname : '';
  return currentUrlPage.split('/')[1] === 'loja';
};
