import React from 'react';

const SvgIconLandscape = props => (
  <svg width={57} height={42} viewBox="0 0 57 42" {...props}>
    <defs>
      <path id="icon-landscape_svg__a" d="M0 41.571h61.83V0H0z" />
      <path id="icon-landscape_svg__c" d="M.096 0h46.277v31.861H.096z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#F9DCDF"
        d="M14.723 1.268H53.43c1.068 0 1.935.79 1.935 1.765v26.474c0 .974-.867 1.764-1.935 1.764H14.723c-1.069 0-1.935-.79-1.935-1.764V3.033c0-.974.866-1.765 1.935-1.765"
      />
      <path
        fill="#FCA5AD"
        d="M21.673 7.933c-.244.909-.867 1.688-1.732 2.166a4.005 4.005 0 0 1-2.871.375 3.762 3.762 0 0 1-1.914-1.085V4.854c0-.244.212-.442.474-.442h4.869c1.068.877 1.52 2.235 1.174 3.521"
      />
      <path
        fill="#FCC3C6"
        d="M19.779 7.933c-.333 1.247-1.373 2.223-2.71 2.541a3.762 3.762 0 0 1-1.913-1.085V4.854c0-.244.213-.442.474-.442h2.974c1.068.877 1.521 2.235 1.175 3.521"
      />
      <path
        fill="#FFF"
        d="M12.906 30.922h2.842c-1.045-.002-1.891-.79-1.894-1.764V2.684c.003-.973.85-1.762 1.894-1.765h-2.842c-1.045.003-1.891.792-1.894 1.765v26.474c.003.973.85 1.762 1.894 1.764"
      />
      <mask id="icon-landscape_svg__b" fill="#fff">
        <use xlinkHref="#icon-landscape_svg__a" />
      </mask>
      <path fill="#FFF" d="M10.42 29.12h1.894V2.649H10.42z" mask="url(#icon-landscape_svg__b)" />
      <path
        fill="#FF1D46"
        d="M11.367 24.709c-.523 0-.947.395-.947.882v3.53h1.894v-3.53c0-.487-.424-.882-.947-.882m.947-22.061H10.42V18.53c0 .488.424.883.947.883.523 0 .947-.395.947-.883V2.648z"
        mask="url(#icon-landscape_svg__b)"
      />
      <path
        fill="#FF1D46"
        d="M10.401 22.126a.9.9 0 0 0 .274.628l.142.106a.74.74 0 0 0 .17.08.636.636 0 0 0 .17.053c.062.01.125.016.188.018.25-.004.49-.095.671-.257a.902.902 0 0 0 .198-.292.861.861 0 0 0-.198-.965.983.983 0 0 0-.311-.185.996.996 0 0 0-.36-.071.968.968 0 0 0-.67.256.848.848 0 0 0-.274.629"
      />
      <g transform="translate(10.305)">
        <mask id="icon-landscape_svg__d" fill="#fff">
          <use xlinkHref="#icon-landscape_svg__c" />
        </mask>
        <path
          fill="#FF1D46"
          d="M43.54 31.861H2.929c-1.565 0-2.833-1.188-2.833-2.655 0-.489.423-.885.944-.885.522 0 .945.396.945.885s.423.885.944.885h40.61c.523 0 .946-.396.946-.885V2.656c0-.49-.423-.886-.945-.886H2.929c-.521 0-.944.396-.944.885s-.423.885-.945.885c-.521 0-.944-.396-.944-.885C.096 1.19 1.364 0 2.929 0h40.61c1.566 0 2.835 1.189 2.835 2.655v26.551c0 1.467-1.269 2.655-2.834 2.655"
          mask="url(#icon-landscape_svg__d)"
        />
      </g>
      <g fill="#FF1D46">
        <path d="M16.064 26.508h34.963V5.378H16.064v21.13zm35.435 1.761H15.592c-.783 0-1.418-.591-1.418-1.32V4.938c0-.73.635-1.321 1.418-1.321h35.907c.783 0 1.418.59 1.418 1.32v22.01c0 .73-.635 1.321-1.418 1.321zM.945 17.704c-.522 0-.945-.394-.945-.88v-2.641c0-1.46 1.27-2.642 2.835-2.642H5.67c.522 0 .945.395.945.88 0 .487-.423.881-.945.881H2.835c-.522 0-.945.394-.945.88v2.642c0 .486-.423.88-.945.88M5.67 41.475H2.835C1.269 41.475 0 40.293 0 38.834v-2.641c0-.487.423-.88.945-.88s.945.393.945.88v2.64c0 .487.423.881.945.881H5.67c.522 0 .945.394.945.88 0 .487-.423.881-.945.881m34.962 0h-2.835c-.521 0-.944-.394-.944-.88 0-.487.423-.88.944-.88h2.835c.522 0 .945-.395.945-.881v-2.641c0-.487.424-.88.945-.88.522 0 .945.393.945.88v2.64c0 1.46-1.27 2.642-2.835 2.642m-29.293 0h-1.89c-.521 0-.944-.394-.944-.88 0-.487.423-.88.944-.88h1.89c.522 0 .945.393.945.88 0 .486-.423.88-.945.88m5.671 0h-1.89c-.523 0-.946-.394-.946-.88 0-.487.423-.88.945-.88h1.89c.522 0 .945.393.945.88 0 .486-.423.88-.945.88m5.67 0h-1.89c-.522 0-.945-.394-.945-.88 0-.487.423-.88.945-.88h1.89c.521 0 .944.393.944.88 0 .486-.423.88-.944.88m5.669 0h-1.89c-.522 0-.945-.394-.945-.88 0-.487.423-.88.945-.88h1.89c.522 0 .945.393.945.88 0 .486-.423.88-.945.88m5.67 0h-1.89c-.522 0-.945-.394-.945-.88 0-.487.423-.88.945-.88h1.89c.522 0 .945.393.945.88 0 .486-.423.88-.945.88M.945 33.551c-.522 0-.945-.394-.945-.88v-1.76c0-.487.423-.881.945-.881s.945.394.945.88v1.761c0 .486-.423.88-.945.88m0-5.282c-.522 0-.945-.394-.945-.88v-1.761c0-.486.423-.88.945-.88s.945.394.945.88v1.76c0 .487-.423.881-.945.881m0-5.282c-.522 0-.945-.394-.945-.88v-1.762c0-.486.423-.88.945-.88s.945.394.945.88v1.761c0 .487-.423.88-.945.88m41.577 10.565c-.521 0-.945-.394-.945-.88v-1.76c0-.487.424-.881.945-.881.522 0 .945.394.945.88v1.761c0 .486-.423.88-.945.88" />
        <path d="M17.954 11.541c-1.358 0-2.649-.546-3.545-1.496a.838.838 0 0 1 .112-1.214.995.995 0 0 1 1.306.052c.692.756 1.78 1.088 2.819.856 1.038-.231 1.848-.987 2.096-1.953.249-.967-.106-1.982-.92-2.627a.85.85 0 0 1-.335-.846.903.903 0 0 1 .64-.678.998.998 0 0 1 .943.203c1.472 1.21 1.988 3.143 1.295 4.854-.692 1.711-2.448 2.846-4.411 2.85m0 3.521c-.522 0-.945-.394-.945-.88v-.88c0-.487.423-.881.945-.881s.945.394.945.88v.88c0 .487-.423.88-.945.88m7.559-7.042h-.944c-.523 0-.946-.394-.946-.88 0-.487.423-.881.946-.881h.944c.522 0 .945.394.945.88 0 .487-.423.88-.945.88M23.3 13a.981.981 0 0 1-.668-.258l-.669-.622a.838.838 0 0 1 .012-1.234.995.995 0 0 1 1.324-.011l.669.622c.27.252.35.63.204.96A.949.949 0 0 1 23.3 13m-11.961.302h-1.89c-.521 0-.944-.394-.944-.88s.423-.88.944-.88h1.89c.522 0 .945.394.945.88s-.423.88-.945.88" />
      </g>
      <g stroke="#FF1D46" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
        <path d="M16.064 26.508l8.764-6.907a2.524 2.524 0 0 1 3.206.066l7.977 6.841m15.511-4.382l-8.154-6.297a4 4 0 0 0-4.791-.074L31 21.24" />
      </g>
    </g>
  </svg>
);

export default SvgIconLandscape;
